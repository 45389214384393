<template>
  <v-dialog persistent  
    v-model="activeModal"
    :max-width="variables.widthModal"
    class="modal-cadastro">
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
        <v-spacer></v-spacer>
        <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>
      
      <v-card-title class="modal-cadastro-titulo">
        {{ `Caixas - ${estabelecimento.cod}` }}
      </v-card-title>

      <v-card-text>
        <div class="cadastro-usuario modal-lista-caixa">
          <div class="box-table">
            <v-skeleton-loader
              :loading="loadingTable"
              :transition="'fade-transition'"
              height="500"
              type="table">
              <v-data-table
                :headers="headers"
                :search="search"
                :items="listaCaixa"
                width="300px"
                max-width="500px"
                flat
                hide-default-footer
                :loading="loadingPag"
                :items-per-page.sync="itemsPerPage"
                dense>
                  <template v-slot:top>
                    <!-- Select tipo de entidade -->
                    <BrToolBar @search="buscar" :configFilter="{ listaSelect: listKeys, getItens: getCaixas }" />
                  </template>

                  <template v-slot:item.action="{ item }">
                    <v-btn
                      small
                      @click="activeModelFinanceiro(item)"
                      tile
                      outlined 
                      :color="variables.colorSecondary"
                    >
                      Financeiro
                    </v-btn>
                  </template>



                  <template v-slot:footer>
                    <div class="wrapper-footer">
                      <div class="qtd-paginacao">
                        {{ pageStart }}
                        -
                        {{ pageStop }}
                        de
                        {{ totalCaixa }}
                      </div>
                      <v-pagination
                        v-model="page"
                        :length="numberOfPages"
                        circle
                        :disabled="disabled"
                        :total-visible="7"
                        class="box-table-paginacao"
                        v-if="numberOfPages > 1"></v-pagination>
                    </div>
                  </template>
              </v-data-table>
            </v-skeleton-loader>
          </div>

          <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>

    <modalOperacoesFinanceiras />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Events from '@/core/service/events'
import listagemTables from '@/views/mixins/listagemTables'

export default {
  name: 'ModalListCaixaEmEstabelicimento',
  components: {
    modalOperacoesFinanceiras: () => import('./components/modalOperacoesFinanceiras'),
    BrToolBar: () => import('./components/toolbar')
  },
  mixins: [listagemTables],
  data: () => ({
    singleSelect: false,
    headers: [
      { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'COD', value: 'cod' },
      { align: 'start', class: 'table-header', text: 'Nome', value: 'name' },
      { align: 'end', class: 'table-header', text: '', value: 'action', sortable: false },
    ],
    tipoBusca: 6,
    estabelecimento: {
      cod: ''
    }
  }),
  computed: {
    ...mapGetters('estabelecimento', ['listaCaixa', 'totalCaixa']),
    listKeys () {
      return {
        id: '',
        cod: '',
        name: ''
      }
    },
    numberOfPages () {
      return Math.ceil(this.totalCaixa / this.$store.getters.ItensPaginacao)
    },

    itemsPerPage () {
      return this.$store.getters.ItensPaginacao
    },

    pageStop () {
      const result = this.$store.getters.ItensPaginacao * this.page
      return result < this.totalCaixa ? result : this.totalCaixa
    },

    pageStart () {
      return this.pageStop - this.listaCaixa.length + 1
    }
  },
  mounted () {
    // const vm = this
    // Events.$on('sms:clearTable', () => {
    //   console.log('chegou aqui')
    //   vm.itensSelect = [] })
    Events.$on('estabelecimento::listaCaixa', item => {
      this.loadingTable = true
      this.estabelecimento = item
      this.addFilter({ _filter: {"Entities:parentId_In": `${item.id }` }})
      this.getCaixas({ _filter: {"Entities:parentId_In": `${item.id }` }}).finally(() => this.loadingTable = false)
      this.activeModal = true
    })
  },
  watch: {
    tipoBusca (v) {
      this.addFiltroAtual(v).then(() => this.filtroEntities(v) )
    },
    itensSelect (val) {
      this.addDadosTable(this.envioSms(val))
    }
  },
  methods: {
    ...mapActions('estabelecimento', ['getCaixas']),

    closeModal () {
      this.activeModal = false
    },

    activeModelFinanceiro (item) {
      Events.$emit('modalListCaixa::operacoesFinanceiras', item)
    },

    // sobrescrevendo o mixin de listagem de tabela para nao fazer requisição atoa
    init () {}
  },

  beforeDestroy () {
    Events.$off('estabelecimento::listaCaixa')
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/table';
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    .v-data-table-header-mobile {
      display: none;
    }
  }

  .fab-cadastrar-usuario {
    position: fixed;
    z-index: 1;
    bottom: 25px;
    right: 5px;

    @media (min-width: 599px) {
      display: none !important;
    }
  }

  .modal-lista-caixa .v-data-table__wrapper table {
    min-width: 800px;
  }
  // .filtro-entities{
  //   position: absolute;
  //   z-index: 1;
  //   top: -16.5px;
  //   @media(max-width: 600px){
  //     position: relative;
  //     width: 100% !important;
  //   }
  // }
</style>>
